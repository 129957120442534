<template>
  <v-dialog v-model="show" max-width="1600px">
    <v-card>
      <v-tabs centered>
        <v-tab>Rubrica</v-tab>
        <v-tab-item align="center">
          <div class="pa-5">
            <v-row>
              <v-col cols="6">
                <h4>Rubrica original</h4>
                <v-text-field
                  v-model="rubricToCompare.dscRubrDadosrubrica"
                  label="Descrição"
                  dense
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="6">
                <h4>Sugestão de rubrica</h4>
                <v-text-field v-model="rubricToAprove.dscRubrDadosrubrica"  dense outlined
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.dscRubrDadosrubrica != rubricToAprove.dscRubrDadosrubrica,
                      }"
                      >Descrição</label
                    ></template
                  >
                </v-text-field>

                <v-text-field
                  v-model="rubricToAprove.aplicabilidade"
                  label="Aplicabilidade"
                  dense
                  outlined
                  persistent-placeholder
              /></v-col>
            </v-row>

            <v-row>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToCompare.tpRubrDadosrubrica"
                  append-icon="mdi-chevron-down"
                  label="Tipo"
                  :items="listaTpRubr"
                  item-text="key"
                  :multiple="false"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToCompare.natRubrDadosrubrica"
                  label="Natureza"
                  :items="listaNaturezas"
                  append-icon="mdi-chevron-down"
                  item-text="key"
                  :multiple="false"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>

              <v-col sm="1">
                <v-text-field
                  v-model="rubricToCompare.iniValidIderubrica"
                  v-mask="'##/####'"
                  label="Início da validade"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="1">
                <v-text-field
                  v-model="rubricToCompare.fimValidIderubrica"
                  v-mask="'##/####'"
                  label="Fim da validade"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                /> </v-col
              ><v-divider vertical></v-divider>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToAprove.tpRubrDadosrubrica"
                  :items="listaTpRubr"
                  item-text="key"
                  :multiple="false"
                  append-icon="mdi-chevron-down"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.tpRubrDadosrubrica != rubricToAprove.tpRubrDadosrubrica,
                      }"
                      >Tipo</label
                    ></template
                  ></v-autocomplete
                >
              </v-col>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToAprove.natRubrDadosrubrica"
                  :items="listaNaturezas"
                  item-text="key"
                  :multiple="false"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.natRubrDadosrubrica != rubricToAprove.natRubrDadosrubrica,
                      }"
                      >Natureza</label
                    ></template
                  ></v-autocomplete
                >
              </v-col>

              <v-col sm="1">
                <v-text-field
                  v-model="rubricToAprove.iniValidIderubrica"
                  v-mask="'##/####'"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.iniValidIderubrica != rubricToAprove.iniValidIderubrica,
                      }"
                      >Início da validade</label
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col sm="1">
                <v-text-field
                  v-model="rubricToAprove.fimValidIderubrica"
                  v-mask="'##/####'"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.fimValidIderubrica != rubricToAprove.fimValidIderubrica,
                      }"
                      >Fim da validade</label
                    ></template
                  >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToCompare.codIncCPDadosrubrica"
                  label="cod. Inc. CP"
                  append-icon="mdi-chevron-down"
                  :items="listaCodIncCP"
                  item-text="key"
                  :multiple="false"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToCompare.codIncIRRFDadosrubrica"
                  label="cod. Inc. IRRF"
                  :items="listaCodIncIR"
                  item-text="key"
                  :multiple="false"
                  name="off"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToCompare.codIncFGTSDadosrubrica"
                  label="cod. Inc. FGTS"
                  :items="listaCodIncFgts"
                  item-text="key"
                  :multiple="false"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                /> </v-col
              ><v-divider vertical></v-divider>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToAprove.codIncCPDadosrubrica"
                  :items="listaCodIncCP"
                  item-text="key"
                  :multiple="false"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.codIncCPDadosrubrica != rubricToAprove.codIncCPDadosrubrica,
                      }"
                      >cod. Inc. CP</label
                    ></template
                  ></v-autocomplete
                >
              </v-col>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToAprove.codIncIRRFDadosrubrica"
                  :items="listaCodIncIR"
                  item-text="key"
                  :multiple="false"
                  name="off"
                  dense
                  hide-details
                  append-icon="mdi-chevron-down"
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.codIncIRRFDadosrubrica != rubricToAprove.codIncIRRFDadosrubrica,
                      }"
                      >cod. Inc. IRRF</label
                    ></template
                  ></v-autocomplete
                >
              </v-col>
              <v-col sm="2">
                <v-autocomplete
                  v-model="rubricToAprove.codIncFGTSDadosrubrica"
                  :items="listaCodIncFgts"
                  item-text="key"
                  :multiple="false"
                  dense
                  hide-details
                  append-icon="mdi-chevron-down"
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.codIncFGTSDadosrubrica != rubricToAprove.codIncFGTSDadosrubrica,
                      }"
                      >cod. Inc. FGTS</label
                    ></template
                  ></v-autocomplete
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-textarea
                  v-model="rubricToCompare.observacaoDadosrubrica"
                  label="Observação"
                  :counter="255"
                  dense
                  outlined
                  persistent-placeholder
                /> </v-col
              ><v-divider vertical></v-divider>
              <v-col sm="6">
                <v-textarea
                  v-model="rubricToAprove.observacaoDadosrubrica"
                  :counter="255"
                  outlined
                  persistent-placeholder
                  ><template v-slot:label
                    ><label
                      v-bind:class="{
                        warning: rubricToCompare.observacaoDadosrubrica != rubricToAprove.observacaoDadosrubrica,
                      }"
                      >Observação</label
                    ></template
                  ></v-textarea
                >
              </v-col>
            </v-row>
          </div>
        </v-tab-item>

        <v-tab>Processos</v-tab>
        <v-tab-item>
          <div class="pa-5">
            <v-row>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoCpHeader"
                  :items="listaProcessoCp"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoCp"
                >
                  <template v-slot:item.codSuspIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoCP">
                      {{ item.codSuspIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.extDecisaoIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.extDecisaoIdeProcessoCP">
                      {{ item.extDecisaoIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.nrProcIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoCP">
                      {{ item.nrProcIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.tpProcIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.tpProcIdeProcessoCP">
                      {{ item.tpProcIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoCpHeader"
                  :items="listaProcessoCp"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoCp"
                >
                  <template v-slot:item.codSuspIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoCP">
                      {{ item.codSuspIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.extDecisaoIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.extDecisaoIdeProcessoCP">
                      {{ item.extDecisaoIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.nrProcIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoCP">
                      {{ item.nrProcIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.tpProcIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.tpProcIdeProcessoCP">
                      {{ item.tpProcIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoFgtsHeader"
                  :items="listaProcessoFgts"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoFgts"
                >
                  <template v-slot:item.nrProcIdeProcessoFGTS="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoFGTS">
                      {{ item.nrProcIdeProcessoFGTS }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoFgtsHeader"
                  :items="listaProcessoFgts"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoFgts"
                >
                  <template v-slot:item.nrProcIdeProcessoFGTS="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoFGTS">
                      {{ item.nrProcIdeProcessoFGTS }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoIrffHeader"
                  :items="listaProcessoIrrf"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoIrff"
                >
                  <template v-slot:item.nrProcIdeProcessoIRRF="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoIRRF">
                      {{ item.nrProcIdeProcessoIRRF }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codSuspIdeProcessoIRRF="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoIRRF">
                      {{ item.codSuspIdeProcessoIRRF }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoIrffHeader"
                  :items="listaProcessoIrrf"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoIrff"
                >
                  <template v-slot:item.nrProcIdeProcessoIRRF="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoIRRF">
                      {{ item.nrProcIdeProcessoIRRF }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codSuspIdeProcessoIRRF="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoIRRF">
                      {{ item.codSuspIdeProcessoIRRF }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoSindHeader"
                  :items="listaProcessoSind"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoSind"
                >
                  <template v-slot:item.nrProcIdeProcessoSIND="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoSIND">
                      {{ item.nrProcIdeProcessoSIND }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codSuspIdeProcessoSIND="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoSIND">
                      {{ item.codSuspIdeProcessoSIND }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-data-table
                  :headers="listaProcessoSindHeader"
                  :items="listaProcessoSind"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoSind"
                >
                  <template v-slot:item.nrProcIdeProcessoSIND="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoSIND">
                      {{ item.nrProcIdeProcessoSIND }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codSuspIdeProcessoSIND="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoSIND">
                      {{ item.codSuspIdeProcessoSIND }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close"> Cancelar</v-btn>
        <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="justify"> Continuar </v-btn>
      </v-card-actions>
    </v-card>
    <modal-justify
      ref="modalJustify"
      :suggestion="rubricToAproveAndJustify"
      :rubric="rubricToCompareAndJustify"
      @clean="refresh"
      @close="close"
    />
  </v-dialog>
</template>

<script>
import descCodigosVSelect from '@/assets/json/descCodigosVSelect.json';
import eSocialService from '@/services/eSocialService.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalApproveRubrica',
  props: ['rubric', 'suggestion'],
  components: {
    ModalJustify: () => import('./ModalJustify.vue'),
  },

  data() {
    return {
      show: false,
      rubricToCompareAndJustify: {},
      rubricToAproveAndJustify: {},
      loadingSave: false,
      activeColor: 'primary',
      listaProcessoCp: [],
      listaProcessoIrrf: [],
      listaProcessoFgts: [],
      listaProcessoSind: [],
      listaNaturezas: descCodigosVSelect.natRubr,
      listaTpRubr: descCodigosVSelect.tpRubr,
      listaCodIncCP: descCodigosVSelect.codIncCP,
      listaCodIncIR: descCodigosVSelect.codIncIR,
      listaCodIncFgts: descCodigosVSelect.codIncFGTS,
      listaProcessoCpHeader: [
        {
          align: 'center',
          text: 'Código do indicativo da suspensão Contribuição Previdenciária',
          value: 'codSuspIdeProcessoCP',
        },
        {
          align: 'center',
          text: 'Extensão da decisão/sentença Contribuição Previdenciária',
          value: 'extDecisaoIdeProcessoCP',
        },
        { align: 'center', text: 'Número do Processo Contribuição Previdenciária', value: 'nrProcIdeProcessoCP' },
        { align: 'center', text: 'Tipo de Processo Contribuição Previdenciária', value: 'tpProcIdeProcessoCP' },
      ],

      listaProcessoIrffHeader: [
        { align: 'center', text: 'Número do Processo IRRF', value: 'nrProcIdeProcessoIRRF' },
        { align: 'center', text: 'Código do indicativo da suspensão IRRF', value: 'codSuspIdeProcessoIRRF' },
      ],

      listaProcessoFgtsHeader: [{ align: 'center', text: 'Número do Processo FGTS', value: 'nrProcIdeProcessoFGTS' }],
      listaProcessoSindHeader: [
        { align: 'center', text: 'Número do Processo Contribuição Sindical', value: 'nrProcIdeProcessoSIND' },
      ],
    };
  },

  created() {
    this.init();
  },

  computed: {
    rubricToAprove() {
      return this.suggestion;
    },
    rubricToCompare() {
      return this.rubric;
    },
    ...mapGetters({
      usuario: 'user/getUserDetails',
    }),
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    open() {
      this.show = true;
    
    },

    close() {
      this.show = false;
      this.$emit('clean', '');
      this.$emit('reload', '');
    },

    justify() {
      this.rubricToAproveAndJustify = cloneDeep(this.rubricToAprove);
      this.rubricToCompareAndJustify = this.rubricToCompare;
      this.$refs.modalJustify.$emit('open');
    },
    refresh() {
      this.rubricToCompareAndJustify = {};
      this.rubricToAproveAndJustify = {};
    },

    loadInfoCP(item) {
      let listado = item.listEvtTabRubricaIdeProcessoCP;

      this.listaProcessoCp = listado;
    },

    loadInfoFGTS(item) {
      let listado = item.listEvtTabRubricaIdeProcessoFGTS;
      this.listaProcessoFgts = listado;
    },

    loadInfoIRRF(item) {
      let listado = item.listEvtTabRubricaIdeProcessoIRRF;
      this.listaProcessoIrrf = listado;
    },
    loadInfoSIND(item) {
      let listado = item.listEvtTabRubricaIdeProcessoSIND;
      this.listaProcessoSindi = listado;
    },
  },
};
</script>
