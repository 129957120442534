var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1600px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            { attrs: { centered: "" } },
            [
              _c("v-tab", [_vm._v("Rubrica")]),
              _c("v-tab-item", { attrs: { align: "center" } }, [
                _c(
                  "div",
                  { staticClass: "pa-5" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("h4", [_vm._v("Rubrica original")]),
                            _c("v-text-field", {
                              attrs: {
                                label: "Descrição",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToCompare.dscRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "dscRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.dscRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("h4", [_vm._v("Sugestão de rubrica")]),
                            _c("v-text-field", {
                              attrs: { dense: "", outlined: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .dscRubrDadosrubrica !=
                                              _vm.rubricToAprove
                                                .dscRubrDadosrubrica,
                                          },
                                        },
                                        [_vm._v("Descrição")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.rubricToAprove.dscRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "dscRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToAprove.dscRubrDadosrubrica",
                              },
                            }),
                            _c("v-text-field", {
                              attrs: {
                                label: "Aplicabilidade",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToAprove.aplicabilidade,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "aplicabilidade",
                                    $$v
                                  )
                                },
                                expression: "rubricToAprove.aplicabilidade",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                "append-icon": "mdi-chevron-down",
                                label: "Tipo",
                                items: _vm.listaTpRubr,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToCompare.tpRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "tpRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.tpRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "Natureza",
                                items: _vm.listaNaturezas,
                                "append-icon": "mdi-chevron-down",
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToCompare.natRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "natRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.natRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "1" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/####",
                                  expression: "'##/####'",
                                },
                              ],
                              attrs: {
                                label: "Início da validade",
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToCompare.iniValidIderubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "iniValidIderubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.iniValidIderubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "1" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/####",
                                  expression: "'##/####'",
                                },
                              ],
                              attrs: {
                                label: "Fim da validade",
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToCompare.fimValidIderubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "fimValidIderubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.fimValidIderubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.listaTpRubr,
                                "item-text": "key",
                                multiple: false,
                                "append-icon": "mdi-chevron-down",
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .tpRubrDadosrubrica !=
                                              _vm.rubricToAprove
                                                .tpRubrDadosrubrica,
                                          },
                                        },
                                        [_vm._v("Tipo")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.rubricToAprove.tpRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "tpRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression: "rubricToAprove.tpRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.listaNaturezas,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .natRubrDadosrubrica !=
                                              _vm.rubricToAprove
                                                .natRubrDadosrubrica,
                                          },
                                        },
                                        [_vm._v("Natureza")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.rubricToAprove.natRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "natRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToAprove.natRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "1" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/####",
                                  expression: "'##/####'",
                                },
                              ],
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .iniValidIderubrica !=
                                              _vm.rubricToAprove
                                                .iniValidIderubrica,
                                          },
                                        },
                                        [_vm._v("Início da validade")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.rubricToAprove.iniValidIderubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "iniValidIderubrica",
                                    $$v
                                  )
                                },
                                expression: "rubricToAprove.iniValidIderubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "1" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/####",
                                  expression: "'##/####'",
                                },
                              ],
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .fimValidIderubrica !=
                                              _vm.rubricToAprove
                                                .fimValidIderubrica,
                                          },
                                        },
                                        [_vm._v("Fim da validade")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.rubricToAprove.fimValidIderubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "fimValidIderubrica",
                                    $$v
                                  )
                                },
                                expression: "rubricToAprove.fimValidIderubrica",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "cod. Inc. CP",
                                "append-icon": "mdi-chevron-down",
                                items: _vm.listaCodIncCP,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value: _vm.rubricToCompare.codIncCPDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "codIncCPDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.codIncCPDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "cod. Inc. IRRF",
                                items: _vm.listaCodIncIR,
                                "item-text": "key",
                                multiple: false,
                                name: "off",
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubricToCompare.codIncIRRFDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "codIncIRRFDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.codIncIRRFDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "cod. Inc. FGTS",
                                items: _vm.listaCodIncFgts,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubricToCompare.codIncFGTSDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "codIncFGTSDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.codIncFGTSDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.listaCodIncCP,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .codIncCPDadosrubrica !=
                                              _vm.rubricToAprove
                                                .codIncCPDadosrubrica,
                                          },
                                        },
                                        [_vm._v("cod. Inc. CP")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.rubricToAprove.codIncCPDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "codIncCPDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToAprove.codIncCPDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.listaCodIncIR,
                                "item-text": "key",
                                multiple: false,
                                name: "off",
                                dense: "",
                                "hide-details": "",
                                "append-icon": "mdi-chevron-down",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .codIncIRRFDadosrubrica !=
                                              _vm.rubricToAprove
                                                .codIncIRRFDadosrubrica,
                                          },
                                        },
                                        [_vm._v("cod. Inc. IRRF")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value:
                                  _vm.rubricToAprove.codIncIRRFDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "codIncIRRFDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToAprove.codIncIRRFDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "2" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.listaCodIncFgts,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "hide-details": "",
                                "append-icon": "mdi-chevron-down",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .codIncFGTSDadosrubrica !=
                                              _vm.rubricToAprove
                                                .codIncFGTSDadosrubrica,
                                          },
                                        },
                                        [_vm._v("cod. Inc. FGTS")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value:
                                  _vm.rubricToAprove.codIncFGTSDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "codIncFGTSDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToAprove.codIncFGTSDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                label: "Observação",
                                counter: 255,
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubricToCompare.observacaoDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToCompare,
                                    "observacaoDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToCompare.observacaoDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                counter: 255,
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          class: {
                                            warning:
                                              _vm.rubricToCompare
                                                .observacaoDadosrubrica !=
                                              _vm.rubricToAprove
                                                .observacaoDadosrubrica,
                                          },
                                        },
                                        [_vm._v("Observação")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value:
                                  _vm.rubricToAprove.observacaoDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubricToAprove,
                                    "observacaoDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubricToAprove.observacaoDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-tab", [_vm._v("Processos")]),
              _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "pa-5" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoCp",
                              attrs: {
                                headers: _vm.listaProcessoCpHeader,
                                items: _vm.listaProcessoCp,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.codSuspIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoCP
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.extDecisaoIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.extDecisaoIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "extDecisaoIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "extDecisaoIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.extDecisaoIdeProcessoCP
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.nrProcIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.nrProcIdeProcessoCP) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.tpProcIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.tpProcIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "tpProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "tpProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.tpProcIdeProcessoCP) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoCp",
                              attrs: {
                                headers: _vm.listaProcessoCpHeader,
                                items: _vm.listaProcessoCp,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.codSuspIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoCP
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.extDecisaoIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.extDecisaoIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "extDecisaoIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "extDecisaoIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.extDecisaoIdeProcessoCP
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.nrProcIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.nrProcIdeProcessoCP) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.tpProcIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.tpProcIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "tpProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "tpProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.tpProcIdeProcessoCP) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoFgts",
                              attrs: {
                                headers: _vm.listaProcessoFgtsHeader,
                                items: _vm.listaProcessoFgts,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoFGTS",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoFGTS,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoFGTS",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoFGTS",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoFGTS
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoFgts",
                              attrs: {
                                headers: _vm.listaProcessoFgtsHeader,
                                items: _vm.listaProcessoFgts,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoFGTS",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoFGTS,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoFGTS",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoFGTS",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoFGTS
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoIrff",
                              attrs: {
                                headers: _vm.listaProcessoIrffHeader,
                                items: _vm.listaProcessoIrrf,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoIRRF",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoIRRF,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoIRRF
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codSuspIdeProcessoIRRF",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoIRRF,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoIRRF
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoIrff",
                              attrs: {
                                headers: _vm.listaProcessoIrffHeader,
                                items: _vm.listaProcessoIrrf,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoIRRF",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoIRRF,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoIRRF
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codSuspIdeProcessoIRRF",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoIRRF,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoIRRF
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoSind",
                              attrs: {
                                headers: _vm.listaProcessoSindHeader,
                                items: _vm.listaProcessoSind,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoSIND",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoSIND,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoSIND
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codSuspIdeProcessoSIND",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoSIND,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoSIND
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoSind",
                              attrs: {
                                headers: _vm.listaProcessoSindHeader,
                                items: _vm.listaProcessoSind,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoSIND",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoSIND,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoSIND
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codSuspIdeProcessoSIND",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoSIND,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoSIND
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.close } }, [
                _vm._v(" Cancelar"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    loading: _vm.loadingSave,
                    disabled: _vm.loadingSave,
                  },
                  on: { click: _vm.justify },
                },
                [_vm._v(" Continuar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-justify", {
        ref: "modalJustify",
        attrs: {
          suggestion: _vm.rubricToAproveAndJustify,
          rubric: _vm.rubricToCompareAndJustify,
        },
        on: { clean: _vm.refresh, close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }